const books = {
    Pasteles: {
        title: "Los Deliciosos Pasteles del Señor T",
        summary: "Conoce a Vainilla, ve el mundo desde sus ojos. Aprende junto con él y el señor T que detrás de los colores, sabores y apariencia de las personas, puedes encontrar muchas sorpresas. Libro animado e interactivo. Toca el pastelillo en las páginas que aparece para descubrir los elementos interactivos. De la colección Gusano de Luz, de Editorial Porrúa.",
        storeLink: "https://books.apple.com/mx/book/los-deliciosos-pasteles-del/id929416945",
    },
    Hora: {
        title: "¡Hora de Bañarse!",
        summary: "Acompaña a los bebés animales en su hora del baño. Descubre las animaciones en cada página en lo que disfrutas la bella canción compuesta para el libro. Audio libro musical, animado e interactivo. De la colección Bebés Consentidos, de Editorial Porrúa. Podrás seguir la canción palabra por palabra, o leer el libro a tu ritmo.",
        storeLink: "https://itunes.apple.com/mx/book/hora-de-banarse!/id984942568",
    },
    Ciempies: {
        title: "Andrés Ciempiés",
        summary: "Acompaña a Andrés a la aventura de su primer paso. Descubre las animaciones en cada página en lo que disfrutas la bella canción compuesta para el libro. Audio libro musical, animado e interactivo. De la colección Bebés Consentidos, de Editorial Porrúa. Podrás seguir la canción palabra por palabra, o leer el libro a tu ritmo.",
        storeLink: "https://itunes.apple.com/mx/book/andres-ciempies/id984942208",
    },
    Comida: {
        title: "La comida está servida",
        summary: "Únete a la comida de esta especial familia de cerditos. Y conoce a su peculiar pequeña. Descubre las animaciones en cada página en lo que disfrutas la bella canción compuesta para el libro. Audio libro musical, animado e interactivo. De la colección Bebés Consentidos, de Editorial Porrúa. Podrás seguir la canción palabra por palabra, o leer el libro a tu ritmo.",
        storeLink: "https://itunes.apple.com/mx/book/la-comida-esta-servida!/id984940404",
    },
    Nana: {
        title: "Nana para un Nenedino",
        summary: "Acompaña a esta familia de dinosaurios a dormir a su pequeño. Descubre las animaciones en cada página en lo que disfrutas la bella canción compuesta para el libro. Audio libro musical, animado e interactivo. De la colección Bebés Consentidos, de Editorial Porrúa. Podrás seguir la canción palabra por palabra, o leer el libro a tu ritmo.",
        storeLink: "https://itunes.apple.com/mx/book/nana-para-un-nenedino/id985469282",
    },
    Familia: {
        title: "La familia Buen Bigote",
        summary: "Bebé Agustín está a punto de decir sus primeras palabras. ¿Qué dirá? Descubre las animaciones en cada página en lo que disfrutas la bella canción compuesta para el libro. Audio libro musical, animado e interactivo. De la colección Bebés Consentidos, de Editorial Porrúa. Podrás seguir la canción palabra por palabra, o leer el libro a tu ritmo.",
        storeLink: "https://itunes.apple.com/mx/book/la-familia-buen-bigote/id984940098",
    },
}




export {books};
